import { Injectable } from '@angular/core';
import { Buoy, Query } from '@buoy/client';
import gql from 'graphql-tag';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class GraphqlServiceService {
  public graphQlQuery: Query;

  public favouriteMovie: Query;
  public QueryData:  Observable<any> ;
  public isPopupShown = false;
  constructor(
    private buoy: Buoy,
    private httpservice: HttpService
  ) {
  }

  queryGraphQl() {
    this.favouriteMovie = this.buoy.query(
      gql`
      query notifications($count: Int!, $page: Int!) {
        notifications(count:$count,page:$page){
          data{
            id,
            title,
            summary,
            status
          }paginatorInfo{
            total,
            perPage,
            hasMorePages,
            currentPage,
            firstItem
          }
        }
    }
      `,
      {
        count: 10,
        page: 1
      },
      {
        scope: 'QueryData'
      }
    );
  }

  Query(query, variables, scope, pagination, callback) {
//    this.lawfirmApiService.setshowLoader(true);
    var options: any = {
      scope: scope,
      fetchPolicy: 'no-cache',
      //'cache-first' | 'cache-and-network' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
      subscribe: true,
      onChange: function ($event) {
        if (callback) {
          callback($event);
        }
        this.httpservice.setshowLoader(false);
      }.bind(this)
    }
    if (pagination) {
      options[scope] = scope;
    }
    this.graphQlQuery = this.buoy.query(gql(query), variables, options);


  }

  refetch() {
    this.graphQlQuery.refetch();
  }

  Mutation(query, variables, options, successCallBack?, errorCallBack?) {
    this.httpservice.setshowLoader(true);
    this.buoy.mutate(gql(query), variables, options).toPromise().then(
      (data) => {
        if (successCallBack) {
          successCallBack(data);
        }
       this.httpservice.setshowLoader(false);
      },
      (error) => {
        // console.log(error);
        // alert(error);
        // 
        if (errorCallBack) {
          errorCallBack();
        } else {
          // (!this.isPopupShown) && (this.lawfirmApiService.triggerSweetAlert('error', 'Oops!', 'Something went wrong!, Try Again!!', false, false));
        }
        // this.isPopupShown = true;
     //   this.lawfirmApiService.setshowLoader(false);
      }
    );
  }


}
